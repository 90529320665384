export const scrollTo = (offset = 0, delay = 10) => {
  setTimeout(() => {
    window.scroll({
      top: offset,
      behavior: 'smooth'
    })
  }, delay)
}

export const scrollToTop = () => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}

export const scrollToError = ({
  delay = 10,
  selector = '.text-error-dark'
} = {}) => {
  setTimeout(() => {
    const error = document.querySelector(selector)

    if (error) {
      error.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest'
      })
    }
  }, delay)
}
