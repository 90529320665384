export const PAW_IMG = '/img/paw.png'
export const SIGNATURE = '/img/jhussey.png'
export const CUDDLY = '/img/cuddly.png'
export const LOGIN_DESKTOP_BG = '/img/login-desktop-bg.png'
export const LOGIN_MOBILE_BG = '/img/login-mobile-bg.png'
export const REGISTER_DESKTOP_BG = '/img/register-desktop-bg.png'
export const REGISTER_MOBILE_BG = '/img/register-mobile-bg.png'
export const DOG_AND_CAT = '/img/dog-and-cat.png'

export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/
export const EIN_REGEX = /[\d]{9}|([\d]{2}-[\d]{7})$/
export const DASHBOARD_TYPE = {
  ADMIN: 'admin',
  DONOR: 'donor',
  PANTRY: 'pantry',
  SHELTER: 'shelter',
  RESCUE: 'rescue'
}
export const EMAIL_REGEX = /^[\w+.-]+@[a-zA-Z0-9-]+(\.([a-zA-Z]{2,7})){1,2}$/
export const YOUTUBE_URL_REGEX =
  /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/|shorts\/)?)([\w-]+)(\S+)?$/i
export const IMAGE_ALLOWED_EXTENSIONS = ['gif', 'jpeg', 'png']
export const US_PHONE = /^(\+1\s+)(\([0-9]{3}\)\s+)([0-9]{3}-[0-9]{4})$/
export const PRODUCTION_URL_REGEX =
  /^(https?:\/\/)?(www\.)?((?!cuddly|poundwishes|pw)[a-zA-Z0-9-]{1,256})\.(com|net|org|gov|info|us|ca|io|co|dog|horse|fish|cricket|pet)(\/[^\s]*)?$/i
export const QA_URL_REGEX =
  /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]{2,256}\.(com|net|org|gov|info|us|ca|io|co|dog|horse|fish|cricket|pet)(\.[a-zA-Z]{2,3})?(\/[^\s]*)?$/i
export const CUDDLY_EMAIL_REGEX =
  /^[a-zA-Z0-9.-_+]{1,256}@(cuddly|poundwishes|pw).([(a-zA-Z)]{2,3})?(.[(a-zA-Z)]{2})+$/gi
export const ONLY_ALLOW_FACEBOOK_REGEX = /^(?:https?:\/\/)?(?:www\.)?[^\s]*facebook\.com[^\s]*$/i
